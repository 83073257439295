<template>
  <div class="cookies-modal p-4 lg:p-8 border-2 border-gray-200">
    <div class="flex flex-col lg:flex-row gap-5 justify-between items-end lg:items-center">
      <div class="font-semibold">
        Web používá cookies k personalizaci obsahu a reklam, poskytování funkcí
        sociálních médií a analýze naší návštěvnosti využíváme soubory cookie.
        Informace o tom, jak náš web používáte, sdílíme se svými partnery pro
        sociální média, inzerci a analýzy.
      </div>
      <div class="flex items-center font-bold">
        <button :class="btnCss" @click="$emit('cookies-handler', false)">
          Odmítnout vše
        </button>
        <button :class="btnCss" @click="$emit('cookies-handler', true)">
          Přijmout vše
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  data: () => {
    return {
      btnCss:
        "text-body-1 font-semibold whitespace-nowrap uppercase border-body-1 hover:bg-blue-100 duration-300 py-3 mx-2 px-4 bg-white rounded-md focus:outline-none border-2",
    };
  },
};
</script>
<style scoped>
.cookies-modal {
  position: fixed;
  z-index: 555;
  bottom: 0px;

  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px 12px 0px 0px;
}
</style>
