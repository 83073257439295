import { LOGIN, LOGOUT, SET_USER, SET_LOGGED_IN } from "@/store/user/constants";
//import { login } from "@/graphql/mutations/userProfileMutations.js";
//import { getUser } from "@/graphql/queries/userProfileQueries.js";
import axios from "axios";
import router from "../../router/index.js";

export default {
  async [LOGIN]({ commit }, { email, password }) {
    let user = {};
    await axios
      .post(`${process.env.VUE_APP_STRAPI_API_URL}/auth/local`, {
        identifier: email,
        password: password,
      })
      .then((response) => {
        user = response.data.user;
        user.jwt = response.data.jwt;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("jwt", user.jwt);

        commit(SET_LOGGED_IN, true);
        commit(SET_USER, user);

        const tokenLife = () => {
          const thisToken = setTimeout(() => {
            commit(SET_LOGGED_IN, false);
            commit(SET_USER, null);
            localStorage.clear();
            router.push("/");
            return clearTimeout(thisToken);
          }, /*86400000*/ 43200000);
        };
        tokenLife();
      })
      .catch();
  },

  async [LOGOUT]({ commit }) {
    await localStorage.clear();
    commit(SET_LOGGED_IN, false);
    commit(SET_USER, null);
  },
};
