import mutations from "@/store/main/mutations";
import actions from "@/store/main/actions";

const currentProperty = JSON.parse(localStorage.getItem("currentProperty"));

const state = {
  invoices: [],
  contracts: [],
  properties: [],
  currentProperty: currentProperty || {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
