import axios from "axios";

export const changePrice = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/changeprice/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getImages = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-images/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getVideos = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-videos/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getImagesNoLogged = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-images/${currentProperty}`
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getVideosNoLogged = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-videos/${currentProperty}`
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getProperties = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const findAddress = async (address) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/findAddress/findAddress/${address}`,
    );
    return data.suggestions;
  } catch (e) {
    return e;
  }
};

export const findCoordinates = async (address) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/findAddress/findCoordinates/${address}`,
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getReservationData = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/reservation-data/check-reservation/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getPurchaseData = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/purchase-data/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getCadastralBuilding = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/cadastral/buildingSearch/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const generateHouseReport = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/generateHouseReport/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return 500;
  }
};

export const getAdverts = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-adverts/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getTopAdverts = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-toping-handlers/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getCadastralData = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/cadastral-data/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getCadastralList = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/cadastral-data/list/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getCompositionList = async (propertyId) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/composition-data/find/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getUserTerms = async (propertyId, type) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-terms/type/${JSON.stringify({
        propertyId,
        type,
      })}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getProvidedTermsByType = async (type) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/provide-terms/provide/${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const createUserTerm = async (term) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/provide-terms/create/${term}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getUserEmail = async (email) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/users-permissions/auth/local/isEmailInDatabase/${email}`
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const putUserData = async (updatedData) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/users-permissions/auth/local/putUserData/${updatedData}`
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const updateProperty = async (updatedData) => {
  try {
    const { data } = await axios.put(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/${updatedData}`
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getMe = async () => {
  try {
    const data = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/users/me`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getAllInvoices = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/getAllInvoices`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getStripe = async (currentProperty) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/invoices/stripe/${currentProperty}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const createStripeTicket = async (requiredData) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/invoices/stripeCreateTicket/${requiredData}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getPropertyInvoices = async (propertyId) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/invoices/property/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getInvoiceData = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/invoices/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getComposition = async (propertyId) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/composition-data/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

//Request to upload on DO provider
export const uploadToDigitalOcean = async (formData) => {
  try {
    const { data } = await axios.post(
      `${process.env.VUE_APP_STRAPI_API_URL}/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};
