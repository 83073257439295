<template>
  <div>
    <header class="header">
      <div class="bg-body-1">
        <div class="w-4/5 mx-auto">
          <nav class="flex w-full justify-between flex-wrap">
            <button @click="checkResult()">
              <OldLogo />
            </button>

            <div class="w-full hidden lg:flex flex-grow justify-end lg:w-auto">
              <div>
                <router-link
                  v-if="!loggedIn"
                  class="font-semibold py-6 select-none block lg:inline-block px-4 hover:bg-body-2 leading-none text-white mt-4 lg:mt-0 focus:outline-none"
                  to="/registrace"
                >
                  Registrovat
                </router-link>
                <router-link
                  v-if="!loggedIn"
                  class="font-semibold py-6 select-none block lg:inline-block px-4 hover:bg-body-2 leading-none text-white mt-4 lg:mt-0 focus:outline-none"
                  to="/prihlaseni"
                >
                  Přihlásit
                </router-link>
                <router-link
                  v-if="loggedIn"
                  class="font-semibold select-none py-6 block lg:inline-block px-4 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                  :class="
                    $route.path.includes('pruvodce/')
                      ? 'router-link-active'
                      : ''
                  "
                  to="/pruvodce/1"
                >
                  Průvodce prodejem
                </router-link>
                <router-link
                  router-link-active
                  router-link-exact-active
                  v-if="loggedIn"
                  class="font-semibold py-6 select-none block lg:inline-block px-4 leading-none text-white mt-4 lg:mt-0 hover:bg-body-2 focus:outline-none"
                  to="/pridat-nemovitost"
                >
                  Vytvořit nemovitost
                </router-link>

                <div class="relative inline-block text-left">
                  <button
                    v-if="loggedIn"
                    class="font-semibold py-6 select-none menu block lg:inline-block px-4 leading-none text-white mt-4 lg:mt-0 focus:outline-none"
                    @click="accountButtonIsOpen = !accountButtonIsOpen"
                  >
                    {{ user.nameSurname }}
                  </button>

                  <div
                    style="z-index: 1000"
                    v-if="accountButtonIsOpen"
                    class="dropdown-menu w-52 select-none origin-top-right left-0 lg:top-8 absolute mt-8 shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                      class="py-1"
                      role="none"
                    >
                      <router-link
                        :to="`/nemovitost`"
                        class="select-none block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Dashboard
                      </router-link>
                    </div>
                    <div
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                      class="py-1"
                      role="none"
                    >
                      <router-link
                        :to="'/nastaveni-osobnich-udaju'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Osobní údaje
                      </router-link>
                    </div>
                    <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/nastaveni-zmena-hesla'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                        >Změna hesla</router-link
                      >
                    </div>
                    <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/doklady'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Doklady
                      </router-link>
                    </div>
                    <div v-show="showAllReceipts" @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/faktura'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Účetnictví
                      </router-link>
                    </div>
                    <!-- Hidden, because deleting properties not work on this page -->
                    <!-- <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/ucet'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Účet
                      </router-link>
                    </div> -->

                    <!-- <div
                      v-if="user.user.isPhotographer"
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                    >
                      <router-link
                        :to="'/vyber-terminu'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Přidat volný termín
                      </router-link>
                    </div> -->

                    <div class="py-1" role="none">
                      <button
                        type="submit"
                        class="block w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-100 hover:text-red-600 focus:outline-none"
                        role="menuitem"
                        @click="
                          (accountButtonIsOpen = !accountButtonIsOpen), logout()
                        "
                      >
                        Odhlásit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-auto block lg:hidden cursor-pointer">
              <Burger v-on:click="menuOpen = !menuOpen" />
            </div>
            <div
              class="w-full flex flex-grow justify-end lg:w-auto lg:hidden"
              :class="menuOpen ? 'flex pb-4' : 'invisible h-0'"
            >
              <div class="w-full">
                <router-link
                  v-if="!loggedIn"
                  class="ml-2 select-none block lg:inline-block px-4 py-2 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                  to="/registrace"
                >
                  <div v-on:click="menuOpen = !menuOpen">Registrovat</div>
                </router-link>
                <router-link
                  v-if="!loggedIn"
                  class="ml-2 select-none block lg:inline-block px-4 py-2 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                  to="/prihlaseni"
                >
                  <div v-on:click="menuOpen = !menuOpen">Přihlásit</div>
                </router-link>
                <router-link
                  v-if="loggedIn"
                  class="ml-2 select-none block lg:inline-block px-4 py-2 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                  :class="
                    $route.path.includes('pruvodce/')
                      ? 'router-link-active'
                      : ''
                  "
                  to="/pruvodce/1"
                >
                  Průvodce prodejem
                </router-link>
                <router-link
                  router-link-active
                  router-link-exact-active
                  v-if="loggedIn"
                  class="ml-2 select-none block lg:inline-block px-4 py-2 leading-none text-white mt-4 lg:mt-0 hover:bg-body-2 focus:outline-none"
                  to="/pridat-nemovitost"
                >
                  Vytvořit nemovitost
                </router-link>

                <div class="relative inline-block text-left">
                  <div>
                    <button
                      v-if="loggedIn"
                      class="ml-2 select-none menu block lg:inline-block px-4 py-2 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                      :class="
                        accountButtonIsOpen || isUserRoute ? 'bg-body-2 ' : ''
                      "
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                    >
                      {{ user.nameSurname }}
                    </button>
                  </div>

                  <div
                    v-if="accountButtonIsOpen"
                    class="dropdown-menu w-52 select-none origin-top-right left-2 lg:top-8 absolute mt-2 shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                      class="py-1"
                      role="none"
                    >
                      <router-link
                        :to="`/nemovitost`"
                        class="select-none block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Dashboard
                      </router-link>
                    </div>
                    <div
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                      class="py-1"
                      role="none"
                    >
                      <router-link
                        :to="'/nastaveni-osobnich-udaju'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Osobní údaje
                      </router-link>
                    </div>
                    <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/nastaveni-zmena-hesla'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                        >Změna hesla</router-link
                      >
                    </div>
                    <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/doklady'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Doklady
                      </router-link>
                    </div>
                    <div v-show="showAllReceipts" @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/faktura'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Účetnictví
                      </router-link>
                    </div>
                    <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                      <router-link
                        :to="'/ucet'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Účet
                      </router-link>
                    </div>

                    <!-- <div
                      v-if="user.user.isPhotographer"
                      @click="accountButtonIsOpen = !accountButtonIsOpen"
                    >
                      <router-link
                        :to="'/vyber-terminu'"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        Přidat volný termín
                      </router-link>
                    </div> -->

                    <div class="py-1" role="none">
                      <button
                        type="submit"
                        class="block w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-100 hover:text-red-600 focus:outline-none"
                        role="menuitem"
                        @click="
                          (accountButtonIsOpen = !accountButtonIsOpen), logout()
                        "
                      >
                        Odhlásit se
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <Navbar v-if="loggedIn" />
  </div>
</template>

<script>
import Navbar from "@/components/dashboard/Navbar";
import { mapActions, mapState } from "vuex";
import { LOGOUT } from "@/store/user/constants";
import { FETCH_PROPERTIES } from "@/store/main/constants";
import Burger from "@/components/svg/Burger.vue";
import OldLogo from "../svg/OldLogo.vue";

export default {
  name: "Header",
  components: {
    Navbar,
    Burger,
    OldLogo,
  },
  data() {
    return {
      accountButtonIsOpen: false,
      isUserRoute: false,
      userDropdownSelected: 0,
      menuOpen: false,
      showAllReceipts: false,
    };
  },
  watch: {
    $route(to /*, from */) {
      this.isUserRoute = to.name && to.name.includes("user");
    },
  },
  methods: {
    ...mapActions("user", {
      logoutStore: LOGOUT,
    }),
    ...mapActions("main", {
      fetchProperties: FETCH_PROPERTIES,
    }),
    async logout() {
      try {
        await this.logoutStore();
        // if the page is not private, you can stay on router name 'preview-page' after logout
        if (this.$router.history.current.name === "preview-page") {
          return;
        } else
          return this.$router.replace(
            { path: "/", params: { ...this.$route.params } },
            () => {
              this.$router.go(0);
            }
          );
      } catch (error) {
        this.$toast("Chyba při odhlášení", {
          className: ["et-alert"],
        });
      }
    },
    checkResult() {
      if (this.loggedIn) {
        return this.$router.push("/nemovitost", () => {});
      } else return this.$router.push("/", () => {});
    },
    menuHandler() {
      this.menuOpen = !this.menuOpen;
    },
  },
  computed: {
    ...mapState("main", ["properties", "contracts", "currentProperty"]),
    ...mapState("user", ["user", "loggedIn"]),
  },
  async mounted() {
    if (this.loggedIn) {
      await this.fetchProperties({
        userProfileId: this.user.id,
      });
    }

    const menu = document.querySelector(".menu");
    document.addEventListener("click", (e) => {
      if (e.target.closest(".menu")) {
        return menu;
      } else return (this.accountButtonIsOpen = false);
    });
  },
  updated() {
    if (this.loggedIn) {
      if (localStorage.getItem("user") && (JSON.parse(localStorage.getItem("user"))["role"]["name"] === "Super Admin")) {
        this.showAllReceipts = true
      } else {
        this.showAllReceipts = false
      }
    }
  },
};
</script>

<style lang="scss">
.header {
  .username {
    @media (max-width: 576px) {
      font-size: 9px;
    }
  }
}
</style>
