var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.name !== 'preview-page')?_c('div',{staticClass:"h-21 inline navbar"},[_c('div',{staticClass:"w-4/5 mx-auto"},[_c('div',{staticClass:"flex flex-wrap justify-between"},[_c('div',{staticClass:"text-left col-span-1 block screen-option"},[(_vm.properties.length > 0 || _vm.currentProperty.name)?_c('div',{staticClass:"menu truncate cursor-pointer mr-2 my-6 select-none font-bold hover:bg-body-2 hover:bg-opacity-25 rounded-xl py-2 text-body-4 inline-flex propertyMenu router-link-option menu-option",class:_vm.$route.path === '/nemovitost' ? 'router-link-active' : '',on:{"click":function($event){return _vm.openIfPropertyIsThere()}}},[_c('div',{staticClass:"w-8 max-h-8",staticStyle:{"margin":"auto"}},[_c('HomeSvg')],1),_c('div',{staticClass:"truncate",staticStyle:{"min-width":"240px","max-width":"240px"}},[_vm._v(" "+_vm._s(_vm.currentProperty.name ? _vm.currentProperty.name : "Vaše nemovitosti")+" ")])]):_vm._e(),_c('router-link',{attrs:{"to":'/nemovitost'}},[(_vm.comboOpen)?_c('div',{staticClass:"truncate lg:top-40 top:80 z-50 origin-top-right pb-2 absolute rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none menu-option"},_vm._l((_vm.properties),function(property){return _c('div',{key:property.id,staticClass:"py-1 truncate"},[_c('button',{staticClass:"truncate overscroll-auto w-full font-bold hover:bg-body-2 hover:bg-opacity-25 py-2 px-3 text-left text-body-4 focus:outline-none",staticStyle:{"min-width":"320px","max-width":"320px"},on:{"click":function($event){(_vm.comboOpen = !_vm.comboOpen), _vm.setCurrentProperty(property)}}},[_vm._v(" "+_vm._s(property.name)+" ")])])}),0):_vm._e()])],1),(_vm.currentProperty.name)?_c('div',{staticClass:"w-full md:w-auto screen-option"},[_c('router-link',{class:[
            this.$route.name === 'strategie' ? 'bg-body-2 bg-opacity-25' : '',
            _vm.linkStyle ],attrs:{"to":'/strategie'}},[_c('img',{staticClass:"mr-2 w-6 h-6 image-option",attrs:{"src":require("../../../public/images/folder-plus.svg")}}),_vm._v(" Strategie a dokumentace ")])],1):_vm._e(),(_vm.currentProperty.name)?_c('div',{staticClass:"lg:justify-end md:w-auto screen-option"},[_c('router-link',{class:[
            this.$route.name === 'priprava-nemovitosti'
              ? 'bg-body-2 bg-opacity-25'
              : '',
            _vm.linkStyle ],attrs:{"to":'/priprava-nemovitosti'}},[_c('img',{staticClass:"mr-2 w-6 h-6 image-option",attrs:{"src":require("../../../public/images/preparation.svg")}}),_vm._v(" Příprava nemovitosti ")])],1):_vm._e(),(_vm.currentProperty.name)?_c('div',{staticClass:"w-full md:w-auto screen-option"},[_c('router-link',{class:[
            this.$route.name === 'inzerce' ? 'bg-body-2 bg-opacity-25' : '',
            _vm.linkStyle ],attrs:{"to":'/inzerce'}},[_c('img',{staticClass:"mr-2 w-6 h-6 image-option",attrs:{"src":require("../../../public/images/inzerce.svg")}}),_vm._v(" Inzerce ")])],1):_vm._e(),(_vm.currentProperty.name)?_c('div',{staticClass:"w-full md:w-auto screen-option"},[_c('router-link',{class:[
            this.$route.name === 'pravni-sluzby'
              ? 'bg-body-2 bg-opacity-25'
              : '',
            _vm.linkStyle ],attrs:{"to":'/pravni-sluzby'}},[_c('img',{staticClass:"mr-2 w-6 h-6 image-option",attrs:{"src":require("../../../public/images/paragraph.svg")}}),_vm._v(" Právní služby ")])],1):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }