<template>
  <svg
    class="header-hamburger-menu"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    v-on="$listeners"
  >
    <path
      d="M3 12H21"
      :stroke="color"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 6H21"
      :stroke="color"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 18H21"
      :stroke="color"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Burger",
  props: {
    color: {
      type: String,
      default: "rgb(226, 226, 226)",
    },
  }
};
</script>
<style scoped>
.header-hamburger-menu:hover {
  stroke: rgb(255, 255, 255);
}
</style>
