import Vue from "vue";
import {
  SET_INVOICES,
  SET_CONTRACTS,
  SET_PROPERTIES,
  SET_CURRENT_PROPERTY,
} from "./constants";

export default {
  [SET_INVOICES](state, data) {
    Vue.set(state, "invoices", data);
  },
  [SET_CONTRACTS](state, data) {
    Vue.set(state, "contracts", data);
  },
  [SET_PROPERTIES](state, data) {
    Vue.set(state, "properties", data);
  },
  [SET_CURRENT_PROPERTY](state, data) {
    Vue.set(state, "currentProperty", data);
    localStorage.setItem("currentProperty", JSON.stringify(data) || null);
    localStorage.removeItem("reservationForm");
  },
};
