import gql from "graphql-tag";
import apolloClient from "@/apolloClient.js";

export const getContracts = async (userProfileId) =>
  await apolloClient.query({
    query: gql`
      query {
        contracts(where: { user: { id: "${userProfileId}" } }) {
          id
          name
          date
          file(where: { name: "${name}" }) {
            id
            name
            url
          }
        }
      }
    `,
  });
