import mutations from "@/store/user/mutations";
import actions from "@/store/user/actions";

const user = JSON.parse(localStorage.getItem("user"));

const state = {
  user: user || null,
  loggedIn: user ? true : false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
