import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/user";
import main from "@/store/main";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    main,
  },
});
