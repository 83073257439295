import Vue from "vue";
import { SET_USER, SET_LOGGED_IN } from "@/store/user/constants";

export default {
  [SET_USER](state, user) {
    Vue.set(state, "user", user);
  },
  [SET_LOGGED_IN](state, loggedIn) {
    Vue.set(state, "loggedIn", loggedIn);
  },
};
