import gql from "graphql-tag";
import apolloClient from "@/apolloClient.js";

export const getProperties = async (userProfileId) =>
  await apolloClient.query({
    query: gql`
      query {
        properties(where: {user_id:  { id: "${userProfileId}" }}) {
          id
          userId
          name
          propertyPrice
          address
          zipCode
          city
          usableAreaApartment
          usableAreaBalcony
          usableAreaCellar
          dispositionViewDescription
          propertyDescription
          surroundingDescription
          outsideViewDescription
          energyAndAdditionalDescription
          propertyType
          propertyState
          propertyDisposition
          propertyOwnership
          propertyAdditional
          latitude
          longitude
        }
      }
    `,
  });
