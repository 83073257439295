// Mutations
export const SET_INVOICES = "SET_INVOICES";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const SET_CURRENT_PROPERTY = "SET_CURRENT_PROPERTY";

// Actions
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const FETCH_PROPERTIES = "FETCH_PROPERTIES";
export const FETCH_CURRENT_PROPERTY = "FETCH_CURRENT_PROPERTY";
