<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    height="64"
    viewBox="0 0 135.46666 33.866667"
    version="1.1"
    id="svg8"
    inkscape:version="1.0.2-2 (e86c870879, 2021-01-15)"
    sodipodi:docname="logos.svg"
  >
    <defs id="defs2" />
    <sodipodi:namedview
      id="base"
      pagecolor="#0a15ff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:zoom="1.979899"
      inkscape:cx="194.74334"
      inkscape:cy="65.604776"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      inkscape:document-rotation="0"
      showgrid="true"
      units="px"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      inkscape:window-x="1912"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
    >
      <inkscape:grid
        type="xygrid"
        id="grid1402"
        empspacing="8"
        originx="67.73333"
        originy="16.933333"
      />
    </sodipodi:namedview>
    <metadata id="metadata5">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g inkscape:label="Vrstva 1" inkscape:groupmode="layer" id="layer1">
      <path
        style="
          opacity: 0.999;
          fill: none;
          fill-opacity: 1;
          stroke: #ffffff;
          stroke-width: 1;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.99611825;
        "
        d="M 6.3499967,21.166667 16.93333,10.583333 27.516663,21.166667 v 0 0"
        id="path1404"
      />
      <text
        xml:space="preserve"
        style="
          font-size: 14.1111px;
          line-height: 1.25;
          fill: #ffffff;
          fill-opacity: 0.97826087;
          stroke: #ffffff;
          stroke-width: 0.165;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.99611825;
        "
        x="32.487331"
        y="23.214165"
        id="text1416"
      >
        <tspan
          class="font"
          sodipodi:role="line"
          id="tspan1414"
          x="32.487331"
          y="23.214165"
          style="
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 14.1111px;
            fill: #ffffff;
            fill-opacity: 1;
            stroke: #ffffff;
            stroke-width: 0.165;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 0.99611825;
          "
        >
          MakléřeNechci
        </tspan>
      </text>
      <circle
        style="
          opacity: 0.999;
          fill: none;
          fill-opacity: 1;
          stroke: #ffffff;
          stroke-width: 1;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.99611825;
          paint-order: stroke markers fill;
        "
        id="path1424"
        cx="16.933331"
        cy="19.049999"
        r="2.1166666"
      />
      <path
        style="
          fill: none;
          stroke: #ffffff;
          stroke-width: 1;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 0.99611825;
          paint-order: stroke markers fill;
        "
        d="m 18.52083,20.6375 2.645833,2.645833"
        id="path1428"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.font {
  font-family: "Inter", sans-serif;
}
</style>
