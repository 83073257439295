import gql from "graphql-tag";
import apolloClient from "@/apolloClient.js";

export const getInvoices = async (userId) =>
  await apolloClient.query({
    query: gql`
      query {
        invoices(where: { user: { id: "${userId}" } }) {
          id
          name
          date
          amount
        }
      }
    `,
  });
