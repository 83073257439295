<template>
  <div id="content" class="flex flex-col h-screen justify-between">
    <IdleMessage v-if="isUserIdle" />
    <Header v-if="!isThatReceipt && showOldHeader" />
    <OldHeader v-if="!isThatReceipt && !showOldHeader" />
    <router-view class="mb-auto"></router-view>
    <Footer v-if="!isThatReceipt" />
    <div style="display: inline-block"></div>
    <Cookies
      v-if="cookiesModalShow"
      v-on:cookies-handler="cookiesHandler($event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Header from "@/components/header/Header.vue";
import OldHeader from "@/components/header/OldHeader.vue";
import Footer from "@/components/footer/Footer.vue";
import Cookies from "@/components/modals/Cookies.vue";
import IdleMessage from "@/components/IdleMessage.vue";
import VueCookies from "vue-cookies";
import { mapState } from "vuex";

let resetTimeout;

export default {
  components: {
    Header,
    OldHeader,
    Cookies,
    Footer,
    IdleMessage,
  },
  computed: {
    ...mapState("main", ["contracts", "currentProperty"]),
    ...mapState("user", ["user", "loggedIn"]),
  },
  methods: {
    async isThisReceipt() {
      const receiptRoute = this.$router.options.routes.find(
        (i) => i.name === "doklad"
      );
      const rcp = receiptRoute.name;
      const location = window.location.pathname.split("/").splice(1, 1);
      const loc = location[0];

      if (this.loggedIn && rcp === "doklad" && loc === "doklad") {
        this.isThatReceipt = true;
      } else {
        this.isThatReceipt = false;
      }
    },
    cookiesHandler(accept) {
      this.$cookies.set("loadScripts", accept ? "true" : "false");
      return (this.cookiesModalShow = !this.cookiesModalShow);
    },
    setIsUserIdle() {
      resetTimeout = setTimeout(() => {
        this.isUserIdle = true;
      }, 60 * 60 * 1000);
    },
    handleKeyDown(e) {
      if (this.isUserIdle && e.key === "116") {
          this.isUserIdle = false;
        } 
        if (!this.isUserIdle) {
          clearTimeout(resetTimeout)
          this.setIsUserIdle();
        }
    }
  },
  data() {
    return {
      isThatReceipt: false,
      cookiesModalShow: false,
      showOldHeader: false,
      isUserIdle: false,
    };
  },
  mounted() {
    Vue.use(VueCookies);
    this.setIsUserIdle();
    window.addEventListener("keydown", (e) => this.handleKeyDown(e));
  },
  beforeDestroy() {
    window.removeEventListener("keydown", (e) => this.handleKeyDown(e));
  },
  watch: {
    $route() {
      this.isThisReceipt();
      this.showOldHeader = this.$route.path === "/" || this.$route.path === "/inzerce-nemovitosti";
      this.cookiesModalShow = !this.$cookies.get("loadScripts") && (this.$route.path === "/" || this.$route.path === "/inzerce-nemovitosti");
    },
  },
};
</script>
