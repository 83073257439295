<template>
  <div v-if="$route.name !== 'preview-page'" class="h-21 inline navbar">
    <div class="w-4/5 mx-auto">
      <div class="flex flex-wrap justify-between">
        <div class="text-left col-span-1 block screen-option">
          <div
            v-if="properties.length > 0 || currentProperty.name"
            @click="openIfPropertyIsThere()"
            class="menu truncate cursor-pointer mr-2 my-6 select-none font-bold hover:bg-body-2 hover:bg-opacity-25 rounded-xl py-2 text-body-4 inline-flex propertyMenu router-link-option menu-option"
            :class="$route.path === '/nemovitost' ? 'router-link-active' : ''"
          >
            <div style="margin: auto" class="w-8 max-h-8">
              <HomeSvg />
            </div>
            <div style="min-width: 240px; max-width: 240px" class="truncate">
              {{
                currentProperty.name ? currentProperty.name : "Vaše nemovitosti"
              }}
            </div>
          </div>
          <router-link :to="'/nemovitost'">
            <div
              v-if="comboOpen"
              class="truncate lg:top-40 top:80 z-50 origin-top-right pb-2 absolute rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none menu-option"
            >
              <div
                v-for="property in properties"
                :key="property.id"
                class="py-1 truncate"
              >
                <button
                  style="min-width: 320px; max-width: 320px"
                  @click="
                    (comboOpen = !comboOpen), setCurrentProperty(property)
                  "
                  class="truncate overscroll-auto w-full font-bold hover:bg-body-2 hover:bg-opacity-25 py-2 px-3 text-left text-body-4 focus:outline-none"
                >
                  {{ property.name }}
                </button>
              </div>
            </div>
          </router-link>
        </div>

        <div class="w-full md:w-auto screen-option" v-if="currentProperty.name">
          <router-link
            :to="'/strategie'"
            :class="[
              this.$route.name === 'strategie' ? 'bg-body-2 bg-opacity-25' : '',
              linkStyle,
            ]"
          >
            <img
              src="../../../public/images/folder-plus.svg"
              class="mr-2 w-6 h-6 image-option"
            />
            Strategie a dokumentace
          </router-link>
        </div>

        <div
          class="lg:justify-end md:w-auto screen-option"
          v-if="currentProperty.name"
        >
          <router-link
            :to="'/priprava-nemovitosti'"
            :class="[
              this.$route.name === 'priprava-nemovitosti'
                ? 'bg-body-2 bg-opacity-25'
                : '',
              linkStyle,
            ]"
          >
            <img
              src="../../../public/images/preparation.svg"
              class="mr-2 w-6 h-6 image-option"
            />
            Příprava nemovitosti
          </router-link>
        </div>

        <div class="w-full md:w-auto screen-option" v-if="currentProperty.name">
          <router-link
            :to="'/inzerce'"
            :class="[
              this.$route.name === 'inzerce' ? 'bg-body-2 bg-opacity-25' : '',
              linkStyle,
            ]"
          >
            <img
              src="../../../public/images/inzerce.svg"
              class="mr-2 w-6 h-6 image-option"
            />
            Inzerce
          </router-link>
        </div>

        <div class="w-full md:w-auto screen-option" v-if="currentProperty.name">
          <router-link
            :to="'/pravni-sluzby'"
            :class="[
              this.$route.name === 'pravni-sluzby'
                ? 'bg-body-2 bg-opacity-25'
                : '',
              linkStyle,
            ]"
          >
            <img
              src="../../../public/images/paragraph.svg"
              class="mr-2 w-6 h-6 image-option"
            />
            Právní služby
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { LOGIN } from "@/store/user/constants";
import { FETCH_PROPERTIES, SET_CURRENT_PROPERTY } from "@/store/main/constants";
import { getProperties } from "../../services/rest/services";
import HomeSvg from "../svg/Home.vue";

export default {
  name: "Navbar",
  components: {
    HomeSvg,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      comboOpen: false,
      properties: [],
      linkStyle:
        "my-6 select-none font-bold hover:bg-body-2 hover:bg-opacity-25 rounded-xl py-2 text-body-4 inline-flex router-link-option",
    };
  },
  methods: {
    ...mapActions("user", {
      loginStore: LOGIN,
    }),
    ...mapActions("main", {
      fetchProperties: FETCH_PROPERTIES,
    }),
    ...mapMutations("main", {
      setCurrentPropertyStore: SET_CURRENT_PROPERTY,
    }),
    async propertyServices() {
      this.properties = await getProperties();
    },
    async setCurrentProperty(property) {
      this.$store.commit("main/SET_CURRENT_PROPERTY", property);
      window.localStorage.removeItem("price");
    },
    async openIfPropertyIsThere() {
      this.propertyServices();
      if (this.properties.length > 0) {
        this.comboOpen = !this.comboOpen;
      } else {
        this.setCurrentProperty(this.properties[0]);
        this.$router.push("nemovitost", () => {});
      }
      this.$store.commit("main/SET_PROPERTIES");
    },
  },
  async mounted() {
    // USER LOGGED IN ?
    if (!this.user) {
      window.localStorage.clear();
      this.$router.push("/", () => {});
    }
    // GET PROPERTIES
    await this.propertyServices();
    this.$store.commit("main/SET_PROPERTIES", this.properties);
    const menu = document.querySelector(".menu");
    document.addEventListener("click", (e) => {
      if (e.target.closest(".menu")) {
        return menu;
      } else return (this.comboOpen = false);
    });
  },
};
</script>
<style scoped>
.router-link-option {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
@media only screen and (max-width: 1400px) {
  .screen-option {
    font-size: 0.9rem !important;
    line-height: 1.4rem !important;
  }
  .router-link-option {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .screen-option {
    font-size: 0.9rem !important;
    line-height: 1.4rem !important;
  }
  .router-link-option {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .image-option {
    display: none !important;
  }
}
@media only screen and (max-width: 1060px) {
  .router-link-option {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .image-option {
    display: none !important;
  }
  .menu-option {
    font-size: 0.9rem !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 900px) {
  .screen-option {
    font-size: 0rem !important;
    line-height: 1.4rem !important;
  }
  .menu-option {
    font-size: 0.8rem !important;
    width: 200px !important;
  }
  .router-link-option {
    padding-left: 12px !important;
    padding-right: 6px !important;
  }
  .image-option {
    display: flex !important;
  }
}
@media only screen and (max-width: 767px) {
  .screen-option {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
    height: 60px !important;
  }
  .menu-option {
    font-size: 1rem !important;
    width: 300px !important;
  }
  .router-link-option {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .image-option {
    display: flex !important;
  }
}
@media only screen and (max-width: 360px) {
  .screen-option {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
    height: 60px !important;
  }
  .menu-option {
    font-size: 1rem !important;
    width: 240px !important;
  }
  .router-link-option {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .image-option {
    display: none !important;
  }
}
</style>
