<template>
  <footer class="bg-body-1 text-white" :class="removeMargin ? '' : 'mt-8'">
    <div class="max-w-screen-2xl mx-auto flex flex-col items-center lg:justify-center lg:items-start gap-10 lg:gap-0 lg:grid grid-cols-4 mt-16 mb-20 px-0 lg:px-5">
      <button class="flex justify-start" @click="checkResult()">
        <Logo color="#ffffff" />
      </button>
      <div class="flex flex-col gap-3">
        <h2 class="text-xl font-bold select-none">Kontakt</h2>
        <div class="flex flex-col gap-2">
          <p class="select-none">JoViRo, s.r.o.</p>
          <p class="select-none">Petrohradská 1486/6</p>
          <p class="select-none">Vršovice, 101 00 Praha 10</p>
          <p class="select-none">Česká republika</p>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <h2 class="text-xl font-bold select-none">Právní dokumenty</h2>
        <div class="flex flex-col gap-2">
          <router-link :to="'/obecne-narizeni-o-ochrane-osobnich-udaju'">
            <p class="cursor-pointer hover:text-yellow-400">
              Ochrana osobních údajů
            </p>
          </router-link>
          <router-link :to="'/obchodni-podminky'">
            <p class="cursor-pointer hover:text-yellow-400">
              Obchodní podmínky
            </p>
          </router-link>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <h2 class="text-xl font-bold select-none">Social media</h2>
        <div class="flex flex-col gap-2">
          <a class="flex gap-3 text-lg font-light leading-6 cursor-pointer hover:text-yellow-400" href="https://www.facebook.com/MaklereNechci" target="_blank">
            <img width="auto" src="/images/facebook.png" />&nbsp; Facebook
          </a>
          <a class="flex gap-3 text-lg font-light leading-6 cursor-pointer hover:text-yellow-400" href="https://www.instagram.com/maklere_nechci" target="_blank">
            <img width="auto" src="/images/instagram.png" />&nbsp; Instagram
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-center my-8" style="letter-spacing: 0.5px;">
      <a href="https://www.rascasone.com/" target="_blank">
        © {{ dateNowHandler }} Tvorba webu Rascasone s.r.o.
      </a>
    </div>
  </footer>
</template>

<script>
import Logo from "../svg/Logo.vue";
export default {
  name: "Footer",
  components: {
    Logo,
  },
  data() {
    return {
      removeMargin: false,
    }
  },
  mounted() {
    this.removeMargin = this.$route.path === '/' || this.$route.path === '/inzerce-nemovitosti';
  },
  computed: {
    dateNowHandler() {
      return new Date().getFullYear();
    },
  },
  methods: {
    checkResult() {
      if (this.$store.state.user.loggedIn) {
        this.scrollTop();
        return this.$router.push("/nemovitost", () => {});
      } else return this.$router.push("/", () => {});
    },
    scrollTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
