import Vue from "vue";

export const IS_BETA = process.env.VUE_APP_FRONTEND_URL.includes("beta");
export const IS_LOCALHOST = process.env.VUE_APP_FRONTEND_URL.includes("localhost");
export const IS_PROD = process.env.VUE_APP_STRAPI_API_URL.includes("/a");

Vue.prototype.$const = {
  PROPERTY_TYPES: [
    { id: 1, name: "Byt" },
    { id: 2, name: "Dům" },
  ],
  ENERGY_CLASSES: [
    { id: 0, name: "A" },
    { id: 0, name: "B" },
    { id: 0, name: "C" },
    { id: 0, name: "D" },
    { id: 0, name: "E" },
    { id: 0, name: "F" },
    { id: 0, name: "G" },
  ],
  PROPERTY_DISPOSITIONS: [
    { id: 1, name: "Garsonka" },
    { id: 2, name: "1+kk" },
    { id: 3, name: "1+1" },
    { id: 4, name: "2+kk" },
    { id: 5, name: "2+1" },
    { id: 6, name: "3+kk" },
    { id: 7, name: "3+1" },
    { id: 8, name: "4+kk" },
    { id: 9, name: "4+1" },
    { id: 10, name: "5+kk" },
    { id: 11, name: "5+1" },
    { id: 12, name: "6+kk a větší" },
  ],
  PROPERTY_STATES: [
    { id: 1, name: "Špatný" },
    { id: 2, name: "Před rekonstrukcí" },
    { id: 3, name: "Po rekonstrukci" },
    { id: 4, name: "Dobrý" },
    { id: 5, name: "Velmi dobrý" },
    { id: 6, name: "Novostavba" },
  ],
  PROPERTY_OWNERSHIPS: [
    { id: 1, name: "Osobní" },
    { id: 2, name: "Družstevní" },
  ],
  PROPERTY_ADDITIONALS: [
    { id: 1, name: "Balkón" },
    { id: 2, name: "Terasa" },
    { id: 3, name: "Lodžie" },
    { id: 4, name: "Sklep" },
    { id: 5, name: "Parkování" },
    { id: 6, name: "Výtah" },
    { id: 7, name: "Garáž" },
    { id: 8, name: "Klimatizace" },
  ],
  TITTLE_DEED: [
    {
      idx: 1,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1KfnvVI4HEbZGFYD4GI5I1yV"
        : "price_1LhVBCI4HEbZGFYDZG7x2mAa",
    },
    {
      idx: 2,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3CVI4HEbZGFYDDFa8J6D7"
        : "price_1LsS16I4HEbZGFYDuKuM9DTE",
    },
    {
      idx: 3,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3DAI4HEbZGFYDWEdKrbhr"
        : "price_1L7wS0I4HEbZGFYDU5Ine5GD",
    },
    {
      idx: 4,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3DMI4HEbZGFYDgW9hmXQN"
        : "price_1L7wSKI4HEbZGFYDY0GzxO6S",
    },
    {
      idx: 5,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3DTI4HEbZGFYDP6ZqLXhs"
        : "price_1L7wSdI4HEbZGFYDL0wCV63l",
    },
    {
      idx: 6,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3DeI4HEbZGFYDWJsbgC4d"
        : "price_1L7wT0I4HEbZGFYDAPEJ4z1L",
    },
    {
      idx: 7,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3DlI4HEbZGFYD7w1u8yVn"
        : "price_1L7wTkI4HEbZGFYDcZmWzntg",
    },
    {
      idx: 8,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3DyI4HEbZGFYD4GbmH3C7"
        : "price_1LsRvJI4HEbZGFYDFrRKs9U9",
    },
    {
      idx: 9,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3E7I4HEbZGFYD6seYpXtk"
        : "price_1L7wVTI4HEbZGFYDK8y4udv3",
    },
    {
      idx: 10,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3EGI4HEbZGFYDzuFzYhC1"
        : "price_1L7wViI4HEbZGFYDj1aiA6Cf",
    },
    {
      idx: 11,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3hII4HEbZGFYDPBLkbIEd"
        : "price_1L7wW2I4HEbZGFYDzeTk1yWS",
    },
    {
      idx: 12,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3hfI4HEbZGFYDieekQq8X"
        : "price_1L7wWJI4HEbZGFYDqp40OUms",
    },
    {
      idx: 13,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3iAI4HEbZGFYDpwnupCTj"
        : "price_1L7wWXI4HEbZGFYDI3lL66Z4",
    },
    {
      idx: 14,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3idI4HEbZGFYDFRGH1T53"
        : "price_1L7wXLI4HEbZGFYDrmvW4udi",
    },
    {
      idx: 15,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3itI4HEbZGFYDgz2z9YDF"
        : "price_1L7wXZI4HEbZGFYDr2jywCBI",
    },
    {
      idx: 16,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3jKI4HEbZGFYDIS2N6Lyg"
        : "price_1L7wXpI4HEbZGFYD3K9J6O1X",
    },
    {
      idx: 17,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3jhI4HEbZGFYDSEBhXxEn"
        : "price_1L7wY6I4HEbZGFYDxsi4PMT9",
    },
    {
      idx: 18,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3jwI4HEbZGFYDKcS9dsEj"
        : "price_1L7wYLI4HEbZGFYDYwLFkfDV",
    },
    {
      idx: 19,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3kKI4HEbZGFYDsO1tkluS"
        : "price_1L7wYYI4HEbZGFYDZ1nnSGL6",
    },
    {
      idx: 20,
      priceId: IS_BETA || IS_LOCALHOST
        ? "price_1Kg3keI4HEbZGFYDZoFx2Yci"
        : "price_1L7wYoI4HEbZGFYDxdZTgLvX",
    },
  ],
};
