import {
  FETCH_INVOICES,
  SET_INVOICES,
  FETCH_CONTRACTS,
  SET_CONTRACTS,
  FETCH_PROPERTIES,
  SET_PROPERTIES,
} from "@/store/main/constants";
import { getInvoices } from "@/graphql/queries/invoiceQueries.js";
import { getContracts } from "@/graphql/queries/contractQueries.js";
import { getProperties } from "@/graphql/queries/propertyQueries.js";

export default {
  async [FETCH_INVOICES]({ commit }, { userProfileId }) {
    const { invoices } = (await getInvoices(userProfileId)).data;
    commit(SET_INVOICES, invoices);
  },
  async [FETCH_CONTRACTS]({ commit }, { userProfileId }) {
    const { contracts } = (await getContracts(userProfileId)).data;
    commit(SET_CONTRACTS, contracts);
  },
  async [FETCH_PROPERTIES]({ commit }, { userProfileId }) {
    const { properties } = (await getProperties(userProfileId)).data;
    commit(SET_PROPERTIES, properties);
  },
};
