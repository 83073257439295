import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { createStripeTicket } from "../services/rest/services";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "landing-page",
    component: () => import("@/views/LandingPage.vue"),
  },
  {
    path: "/inzerce-nemovitosti",
    name: "landing-page-inzerce",
    component: () => import("@/views/LandingPageInzerce.vue"),
  },
  {
    path: "/old",
    name: "landing-page-old",
    component: () => import("@/views/OldLandingPage.vue"),
  },

  {
    path: "/potvrzeni-registrace-emailem",
    name: "potvrzeni-registrace-emailem",
    component: () =>
      import(
        /* webpackChunkName: "potvrzeni-registrace-emailem" */ "@/views/ConfirmEmailPage.vue"
      ),
  },

  {
    path: "/inzerce/:name/:id",
    name: "preview-page",
    component: () =>
      import(/* webpackChunkName: "preview-page" */ "@/views/PreviewPage.vue"),
  },

  {
    path: "/prihlaseni",
    name: "prihlaseni",
    component: () =>
      import(/* webpackChunkName: "prihlaseni" */ "@/views/LoginPage.vue"),
  },
  {
    path: "/registrace",
    name: "registrace",
    component: () =>
      import(/* webpackChunkName: "registrace" */ "@/views/Registration.vue"),
  },
  {
    path: "/nova-nemovitost",
    name: "nova-nemovitost",
    component: () =>
      import(/* webpackChunkName: "nova-nemovitost" */ "@/views/newProperty/NewProperty.vue"),
  },
  {
    path: "/pruvodce/:page",
    name: "wizard-container",
    component: () =>
      import(
        /* webpackChunkName: "wizard-container" */ "@/views/wizardInfoAddProject/WizardContainer.vue"
      ),
  },
  {
    path: "/o-nas",
    name: "o-nas",
    component: () =>
      import(/* webpackChunkName: "o-nas" */ "@/views/AboutUs.vue"),
  },

  {
    path: "/ucet",
    name: "ucet",
    component: () =>
      import(
        /* webpackChunkName: "ucet" */ "@/views/userDetail/UserAccount.vue"
      ),
  },
  {
    path: "/nemovitost",
    name: "nemovitost",
    component: () =>
      import(
        /* webpackChunkName: "nemovitost" */ "@/views/userDetail/UserDashboard.vue"
      ),
  },
  {
    path: "/nastaveni-zmena-hesla",
    name: "nastaveni-zmena-hesla",
    component: () =>
      import(
        /* webpackChunkName: "nastaveni-zmena-hesla" */ "@/views/userDetail/UserChangePassword.vue"
      ),
  },
  {
    path: "/obnoveni-hesla",
    name: "obnoveni-hesla",
    component: () =>
      import(
        /* webpackChunkName: "obnoveni-hesla" */ "@/views/ResetPasswordPage.vue"
      ),
  },
  {
    path: "/faktura",
    name: "faktura",
    component: () =>
      import(
        /* webpackChunkName: "faktura" */ "@/views/userDetail/AllInvoices.vue"
      ),
  },
  {
    path: "/doklady",
    name: "doklady",
    component: () =>
      import(
        /* webpackChunkName: "doklady" */ "@/views/userDetail/UserInvoices.vue"
      ),
  },
  {
    path: "/doklad/:id",
    name: "doklad",
    component: () =>
      import(
        /* webpackChunkName: "doklad" */ "@/components/invoices/UserInvoice.vue"
      ),
  },
  {
    path: "/nastaveni-osobnich-udaju",
    name: "nastaveni-osobnich-udaju",
    component: () =>
      import(
        /* webpackChunkName: "nastaveni-osobnich-udaju" */ "@/views/userDetail/UserPersonalData.vue"
      ),
  },

  {
    path: "/pridat-nemovitost-informace",
    name: "pridat-nemovitost-informace",
    component: () =>
      import(
        /* webpackChunkName: "pridat-nemovitost-informace" */ "@/views/addProperty/AddPropertyInfo.vue"
      ),
  },
  {
    path: "/pridat-nemovitost",
    name: "pridat-nemovitost",
    component: () =>
      import(
        /* webpackChunkName: "pridat-nemovitost" */ "@/views/addProperty/AddProperty.vue"
      ),
  },
  {
    path: "/strategie-informace",
    name: "strategie-informace",
    component: () =>
      import(
        /* webpackChunkName: "strategie-informace" */ "@/views/strategy/StrategyInfo.vue"
      ),
  },
  {
    path: "/strategie",
    name: "strategie",
    component: () =>
      import(
        /* webpackChunkName: "strategie" */ "@/views/strategy/StrategyAdd.vue"
      ),
  },
  {
    path: "/priprava-nemovitosti-informace",
    name: "priprava-nemovitosti-informace",
    component: () =>
      import(
        /* webpackChunkName: "priprava-nemovitosti-informace" */ "@/views/propertyPreparation/PropertyPreparationInfo.vue"
      ),
  },
  {
    path: "/priprava-nemovitosti",
    name: "priprava-nemovitosti",
    component: () =>
      import(
        /* webpackChunkName: "priprava-nemovitosti" */ "@/views/propertyPreparation/PropertyPreparationAdd.vue"
      ),
  },
  {
    path: "/inzerce-informace",
    name: "inzerce-informace",
    component: () =>
      import(
        /* webpackChunkName: "inzerce-informace" */ "@/views/advertising/AdvertisingInfo.vue"
      ),
  },
  {
    path: "/inzerce",
    name: "inzerce",
    component: () =>
      import(
        /* webpackChunkName: "inzerce" */ "@/views/advertising/AdvertisingAdd.vue"
      ),
  },
  {
    path: "/pravni-sluzby-informace",
    name: "pravni-sluzby-informace",
    component: () =>
      import(
        /* webpackChunkName: "pravni-sluzby-informace" */ "@/views/lawyerServices/LawyerServicesInfo.vue"
      ),
  },
  {
    path: "/pravni-sluzby",
    name: "pravni-sluzby",
    component: () =>
      import(
        /* webpackChunkName: "pravni-sluzby" */ "@/views/lawyerServices/LawyerServicesAdd.vue"
      ),
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: () =>
      import(
        /* webpackChunkName: "payment-success" */ "@/views/PaymentSuccess.vue"
      ),
  },
  {
    path: "/list-vlastnictvi-informace",
    name: "list-vlastnictvi-informace",
    component: () =>
      import(
        /* webpackChunkName: "list-vlastnictvi-informace" */ "@/views/TitleDeedInfo.vue"
      ),
  },
  {
    path: "/vyber-terminu",
    name: "vyber-terminu",
    component: () =>
      import(
        /* webpackChunkName: "vyber-terminu" */ "@/components/datePicker/PhotoTermDatePicker.vue"
      ),
  },
  {
    path: "/vyber-terminu-staging",
    name: "stagingTermSelection",
    component: () =>
      import(
        /* webpackChunkName: "vyber-terminu-staging" */ "@/components/datePicker/TermSelection.vue"
      ),
  },
  {
    path: "/vyber-terminu-foceni",
    name: "photoTermSelection",
    component: () =>
      import(
        /* webpackChunkName: "vyber-terminu-foceni" */ "@/components/datePicker/TermSelection.vue"
      ),
  },
  {
    path: "/vyber-terminu-video",
    name: "videoTermSelection",
    component: () =>
      import(
        /* webpackChunkName: "vyber-terminu-video" */ "@/components/datePicker/TermSelection.vue"
      ),
  },
  {
    path: "/vyber-terminu-model",
    name: "modelTermSelection",
    component: () =>
      import(
        /* webpackChunkName: "vyber-terminu-model" */ "@/components/datePicker/TermSelection.vue"
      ),
  },
  {
    path: "/vyber-terminu-konzultace",
    name: "consultationTermSelection",
    component: () =>
      import(
        /* webpackChunkName: "vyber-terminu-konzultace" */ "@/components/datePicker/TermSelection.vue"
      ),
  },
  {
    path: "/obecne-narizeni-o-ochrane-osobnich-udaju",
    name: "generalDataProtectionRegulation",
    component: () =>
      import(
        /* webpackChunkName: "obecne-narizeni-o-ochrane-osobnich-udaju" */ "@/components/juristicalDocuments/GeneralDataProtectionRegulation.vue"
      ),
  },
  {
    path: "/obchodni-podminky",
    name: "tradeTerms",
    component: () =>
      import(
        /* webpackChunkName: "obchodni-podminky" */ "@/components/juristicalDocuments/TradeTerms.vue"
      ),
  },
];

const router = new VueRouter({
  //mode history is removing # before slash
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const logged = store.state.user.loggedIn;
  let authOut = [
    "registrace",
    "prihlaseni",
    "landing-page",
    "landing-page-inzerce",
    "o-nas",
    "tradeTerms",
    "generalDataProtectionRegulation",
    "obnoveni-hesla",
    "potvrzeni-registrace-emailem",
    "preview-page",
    "nova-nemovitost",
  ];
  // Stripe session payment successfull => send to server sessionId with property data
  if (to.hash.includes("payment-success") && logged) {
    const searchParams = new URLSearchParams(to.hash.split("?")[1]);
    const sessionId = searchParams.get("session_id");
    const service = searchParams.get("service") || "";
    const path = searchParams.get("routePathname");
    try {
      createStripeTicket(
        JSON.stringify({
          userId: store.state.main.currentProperty.userId,
          userEmail: store.state.main.currentProperty.user_id.email,
          propertyId: store.state.main.currentProperty.id,
          sessionId,
          service
        })
      );
    } catch (e) {
      return e;
    }
    return next(path);
  }

  if (!authOut.includes(to.name) && !logged) {
    next("/prihlaseni");
  } else if (to.name === "landing-page" && logged) {
    next("/nemovitost");
  } else if (to.name === "preview-page") {
    next();
  } else if (to.name === "prihlaseni" && logged) {
    next("/");
  } else {
    next();
  }
});

export default router;
