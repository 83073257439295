import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

let jwt = localStorage.getItem("jwt");

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL,
  fetch,
  headers: {
    authorization: jwt ? `Bearer ${jwt}` : "",
  },
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

export default apolloClient;
