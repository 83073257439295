<template>
  <div>
    <header class="header">
      <div class="max-w-screen-xl mx-auto mt-8 relative px-5 lg:px-0">
        <nav class="flex w-full justify-between items-center">
          <button @click="checkResult()">
            <Logo />
          </button>

          <div class="w-full hidden lg:flex flex-grow justify-end lg:w-auto">
            <router-link
              v-if="!loggedIn"
              class="font-bold px-10 py-4"
              to="/prihlaseni"
            >
              Přihlásit se
            </router-link>
            <a
              v-if="!loggedIn"
              class="text-white font-bold bg-body-3 px-5 py-4 rounded-md"
              href="#registration"
            >
              Registrace zdarma
            </a>
            <div id="burger" :class="{
            'active': hamburgerMenuOpen
            }" @click="toggleActive">
                <button type="button" class="burger-button" title="Menu">
                    <span class="burger-bar burger-bar--1"></span>
                    <span class="burger-bar burger-bar--2"></span>
                    <span class="burger-bar burger-bar--3"></span>
                </button>
            </div>
            <router-link
              v-if="loggedIn"
              class="font-semibold select-none py-6 block lg:inline-block px-4 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
              :class="
                $route.path.includes('pruvodce/')
                  ? 'router-link-active'
                  : ''
              "
              to="/pruvodce/1"
            >
              Průvodce prodejem
            </router-link>
            <router-link
              router-link-active
              router-link-exact-active
              v-if="loggedIn"
              class="font-semibold py-6 select-none block lg:inline-block px-4 leading-none text-white mt-4 lg:mt-0 hover:bg-body-2 focus:outline-none"
              to="/pridat-nemovitost"
            >
              Vytvořit nemovitost
            </router-link>

            <div v-if="loggedIn" class="relative inline-block text-left">
              <button
                class="font-semibold py-6 select-none block lg:inline-block px-4 leading-none text-white mt-4 lg:mt-0 focus:outline-none"
                @click="accountButtonIsOpen = !accountButtonIsOpen"
              >
                {{ user.nameSurname }}
              </button>

              <div
                style="z-index: 1000"
                v-if="accountButtonIsOpen"
                class="dropdown-menu w-52 select-none origin-top-right left-0 lg:top-8 absolute mt-8 shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div
                  @click="accountButtonIsOpen = !accountButtonIsOpen"
                  class="py-1"
                  role="none"
                >
                  <router-link
                    :to="`/nemovitost`"
                    class="select-none block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Dashboard
                  </router-link>
                </div>
                <div
                  @click="accountButtonIsOpen = !accountButtonIsOpen"
                  class="py-1"
                  role="none"
                >
                  <router-link
                    :to="'/nastaveni-osobnich-udaju'"
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Osobní údaje
                  </router-link>
                </div>
                <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                  <router-link
                    :to="'/nastaveni-zmena-hesla'"
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                    >Změna hesla</router-link
                  >
                </div>
                <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                  <router-link
                    :to="'/doklady'"
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Doklady
                  </router-link>
                </div>
                <!-- Hidden, because deleting properties not work on this page -->
                <!-- <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                  <router-link
                    :to="'/ucet'"
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Účet
                  </router-link>
                </div> -->

                <!-- <div
                  v-if="user.user.isPhotographer"
                  @click="accountButtonIsOpen = !accountButtonIsOpen"
                >
                  <router-link
                    :to="'/vyber-terminu'"
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Přidat volný termín
                  </router-link>
                </div> -->

                <div class="py-1" role="none">
                  <button
                    type="submit"
                    class="block w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-100 hover:text-red-600 focus:outline-none"
                    role="menuitem"
                    @click="
                      (accountButtonIsOpen = !accountButtonIsOpen), logout()
                    "
                  >
                    Odhlásit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="block lg:hidden cursor-pointer">
            <div id="burger" :class="{
            'active': menuOpen
            }" @click="menuHandler">
                <button type="button" class="burger-button" title="Menu">
                    <span class="burger-bar burger-bar--1"></span>
                    <span class="burger-bar burger-bar--2"></span>
                    <span class="burger-bar burger-bar--3"></span>
                </button>
            </div>
          </div>
          <div
            class="absolute bg-white top-0 left-0 w-full flex lg:hidden" style="margin-top: 50px;"
          >
            <div class="menu w-full" :class="menuOpen && 'active'">
              <router-link
                v-if="!loggedIn"
                class="text-lg font-bold select-none block px-5 py-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
                to="/registrace"
              >
                <div v-on:click="menuOpen = !menuOpen">Registrovat</div>
              </router-link>
              <router-link
                v-if="!loggedIn"
                class="text-lg font-bold select-none block px-5 py-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
                to="/prihlaseni"
              >
                <div v-on:click="menuOpen = !menuOpen">Přihlásit</div>
              </router-link>
              <router-link
                class="text-lg font-bold select-none block px-5 py-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
                to="/"
              >
                <div v-on:click="menuOpen = !menuOpen">Úvodní stránka</div>
              </router-link>
              <router-link
                class="text-lg font-bold select-none block px-5 py-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
                to="/inzerce-nemovitosti"
              >
                <div v-on:click="menuOpen = !menuOpen">Inzerce nemovitosti</div>
              </router-link>
              <!-- <select v-model="this.$route.path" @change="landingMenuHandler($event.target.value)" class="w-full -ml-1 text-lg font-bold select-none px-5 py-3 leading-none focus:outline-none cursor-pointer">
                <option class="p-4" value="/">Úvodní stránka</option>
                <option class="p-4" value="/inzerce-nemovitosti">Inzerce nemovitosti</option>
                <option class="p-4" value="/landing-list-vlastnictvi">List vlastnictví</option>
                <option class="p-4" value="/landing-kupni-smlouva">Kupní smlouva</option>
                <option class="p-4" value="/landing-rezervacni-smlouva">Rezervační smlouva</option>
              </select> -->

              <router-link
                v-if="loggedIn"
                class="text-lg select-none block px-4 py-4 leading-none hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                :class="
                  $route.path.includes('pruvodce/')
                    ? 'router-link-active'
                    : ''
                "
                to="/pruvodce/1"
              >
                Průvodce prodejem
              </router-link>
              <router-link
                router-link-active
                router-link-exact-active
                v-if="loggedIn"
                class="text-lg select-none block px-4 py-4 leading-none text-white mt-4 lg:mt-0 hover:bg-body-2 focus:outline-none"
                to="/pridat-nemovitost"
              >
                Vytvořit nemovitost
              </router-link>

              <div class="relative inline-block text-left">
                <button
                  v-if="loggedIn"
                  class="ml-2 select-none block lg:inline-block px-4 py-2 leading-none text-white hover:bg-body-2 mt-4 lg:mt-0 focus:outline-none"
                  :class="
                    accountButtonIsOpen || isUserRoute ? 'bg-body-2 ' : ''
                  "
                  @click="accountButtonIsOpen = !accountButtonIsOpen"
                >
                  {{ user.nameSurname }}
                </button>

                <div
                  v-if="accountButtonIsOpen"
                  class="dropdown-menu w-52 select-none origin-top-right left-2 lg:top-8 absolute mt-2 shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div
                    @click="accountButtonIsOpen = !accountButtonIsOpen"
                    class="py-1"
                    role="none"
                  >
                    <router-link
                      :to="`/nemovitost`"
                      class="select-none block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Dashboard
                    </router-link>
                  </div>
                  <div
                    @click="accountButtonIsOpen = !accountButtonIsOpen"
                    class="py-1"
                    role="none"
                  >
                    <router-link
                      :to="'/nastaveni-osobnich-udaju'"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Osobní údaje
                    </router-link>
                  </div>
                  <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                    <router-link
                      :to="'/nastaveni-zmena-hesla'"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                      >Změna hesla</router-link
                    >
                  </div>
                  <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                    <router-link
                      :to="'/doklady'"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Doklady
                    </router-link>
                  </div>
                  <div @click="accountButtonIsOpen = !accountButtonIsOpen">
                    <router-link
                      :to="'/ucet'"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-body-1 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Účet
                    </router-link>
                  </div>

                  <!-- <div
                    v-if="user.user.isPhotographer"
                    @click="accountButtonIsOpen = !accountButtonIsOpen"
                  >
                    <router-link
                      :to="'/vyber-terminu'"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Přidat volný termín
                    </router-link>
                  </div> -->

                  <div class="py-1" role="none">
                    <button
                      type="submit"
                      class="block w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-100 hover:text-red-600 focus:outline-none"
                      role="menuitem"
                      @click="
                        (accountButtonIsOpen = !accountButtonIsOpen), logout()
                      "
                    >
                      Odhlásit se
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div
          class="menu bg-white absolute top-15 right-0 w-64 flex flex-col"
          :class="hamburgerMenuOpen && 'active'"
        >
          <router-link
            class="font-bold select-none p-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
            to="/"
          >
            Úvodní stránka
          </router-link>
          <router-link
            class="font-bold select-none p-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
            to="/inzerce-nemovitosti"
          >
            Inzerce nemovitosti
          </router-link>
          <!-- <router-link
            class="text-lg select-none p-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
            to="/landing-list-vlastnictvi"
          >
            List vlastnictví
          </router-link>
          <router-link
            class="text-lg select-none p-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
            to="/landing-kupni-smlouva"
          >
            Kupní smlouva
          </router-link>
          <router-link
            class="text-lg select-none p-4 leading-none hover:bg-body-2 hover:text-white focus:outline-none"
            to="/landing-rezervacni-smlouva"
            exact-active-class="bg-body-2 text-white"
            active-class="bg-white"
          >
            Rezervační smlouva
          </router-link> -->
        </div>
      </div>
    </header>
    <Navbar v-if="loggedIn" />
  </div>
</template>

<script>
import Navbar from "@/components/dashboard/Navbar";
import { mapActions, mapState } from "vuex";
import { LOGOUT } from "@/store/user/constants";
import { FETCH_PROPERTIES } from "@/store/main/constants";
import Logo from "../svg/Logo.vue";

export default {
  name: "Header",
  components: {
    Navbar,
    Logo,
  },
  data() {
    return {
      accountButtonIsOpen: false,
      isUserRoute: false,
      userDropdownSelected: 0,
      menuOpen: false,
      landingMenuOpen: false,
      hamburgerMenuOpen: false,
    };
  },
  watch: {
    $route(to /*, from */) {
      this.isUserRoute = to.name && to.name.includes("user");

      if (["/", "/inzerce-nemovitosti", "/landing-list-vlastnictvi", "/landing-kupni-smlouva", "/landing-rezervacni-smlouva"].includes(this.$route.path)) {
        this.landingMenuOpen = false;
      }
    },
  },
  methods: {
    ...mapActions("user", {
      logoutStore: LOGOUT,
    }),
    ...mapActions("main", {
      fetchProperties: FETCH_PROPERTIES,
    }),
    toggleActive () {
      this.hamburgerMenuOpen = !this.hamburgerMenuOpen
    },
    async logout() {
      try {
        await this.logoutStore();
        // if the page is not private, you can stay on router name 'preview-page' after logout
        if (this.$router.history.current.name === "preview-page") {
          return;
        } else
          return this.$router.replace(
            { path: "/", params: { ...this.$route.params } },
            () => {
              this.$router.go(0);
            }
          );
      } catch (error) {
        this.$toast("Chyba při odhlášení", {
          className: ["et-alert"],
        });
      }
    },
    checkResult() {
      if (this.loggedIn) {
        return this.$router.push("/nemovitost", () => {});
      } else return this.$router.push("/", () => {});
    },
    menuHandler() {
      this.menuOpen = !this.menuOpen;
    },
    landingMenuHandler(value) {
      this.$router.push(value);
      this.menuOpen = false;
    }
  },
  computed: {
    ...mapState("main", ["properties", "contracts", "currentProperty"]),
    ...mapState("user", ["user", "loggedIn"]),
  },
  async mounted() {
    if (this.loggedIn) {
      await this.fetchProperties({
        userProfileId: this.user.id,
      });
    }

    const menu = document.querySelector(".menu");
    document.addEventListener("click", (e) => {
      if (e.target.closest(".menu")) {
        return menu;
      } else return (this.accountButtonIsOpen = false);
    });
  },
};
</script>

<style lang="scss">
.header {
  .username {
    @media (max-width: 576px) {
      font-size: 9px;
    }

  }

  $burger-color: #1B26E2;
  $primary: #2b2b68;

  button {
      cursor: pointer;
  }

  /* remove blue outline */
  button:focus {
      outline: 0;
  }

  #burger {
    margin: auto 0;
    padding-left: 2.4rem;
  }

  .menu {
    max-height: 0;
    transition: max-height 0.25s ease-out, border 0.25s ease-out;
    overflow: hidden;

    &.active {
      max-height: 500px;
      transition: max-height 0.25s ease-in, border 0.25s ease-in;

      @media (min-width: 1024px) {
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 0.25rem;
      }
    }

    .router-link-active {
      background-color: white;

      &:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(72, 81, 231, var(--tw-bg-opacity));
      }
    }
  }

  .burger-button {
      position: relative;
      height: 30px;
      width: 40px;
      display: block;
      z-index: 99;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      pointer-events: all;
      transition: transform .6s cubic-bezier(.165, .84, .44, 1);
  }

  .burger-bar {
      background-color: $burger-color;
      position: absolute;
      top: 50%;
      right: 6px;
      left: 6px;
      height: 3px;
      width: auto;
      margin-top: -1px;
      transition: transform .6s cubic-bezier(.165, .84, .44, 1), opacity .3s cubic-bezier(.165, .84, .44, 1), background-color .6s cubic-bezier(.165, .84, .44, 1);
  }

  .burger-bar--1 {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      top: 40%;
  }

  .burger-bar--2 {
      transform-origin: 100% 50%;
      transform: scaleX(1);
  }

  .burger-button:hover .burger-bar--2 {
      transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
      transform: scaleX(1);
  }

  .burger-bar--3 {
      transform: translateY(6px);
      top: 60%;
  }

  #burger.active .burger-button {
      transform: rotate(-180deg);
  }

  #burger.active .burger-bar--1 {
      transform: rotate(45deg);
      top: 50%;
  }

  #burger.active .burger-bar--2 {
      opacity: 0;
  }

  #burger.active .burger-bar--3 {
      transform: rotate(-45deg);
      top: 50%;
  }
}
</style>
