<template>
  <div class="fixed top-0 h-16 w-full flex justify-center items-center bg-red-500 text-white z-50">
    Z důvodu neaktivity prosím obnovte stránku
  </div>
</template>

<script>
  export default {
    name: "IdleMessage",
  }
</script>