import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "../css/styles.css";
import Rollbar from "rollbar";
import Hotjar from "vue-hotjar";
import { setupCalendar } from "v-calendar";
import VueApollo from "vue-apollo";
import Toast from "vue-easy-toast";
import VueMoment from "vue-moment";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import VCalendar from "v-calendar";
import "@/globalConstants.js";
import apolloClient from "@/apolloClient.js";
import VueMask from "v-mask";
import VueCardFormat from "vue-credit-card-validation";
import { IS_BETA, IS_LOCALHOST, IS_PROD } from "./globalConstants";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faUser, faMobile, faCamera } from "@fortawesome/free-solid-svg-icons";

Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(Toast);
Vue.use(Vuelidate);
Vue.use(VueMoment);
Vue.use(VCalendar, { componentPrefix: "vc" });
Vue.use(VueMask);
Vue.use(VueCardFormat);
Vue.component("font-awesome-icon", FontAwesomeIcon);

library.add(faEye, faUser, faMobile, faCamera);

Vue.use(Hotjar, {
  id: "3034122", // Hotjar Site ID
  isProduction: IS_BETA || IS_PROD ? true : false,
});

if (!IS_LOCALHOST) {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: IS_BETA || IS_LOCALHOST
      ? `${process.env.VUE_APP_ROLLBAR_API_BETA}`
      : `${process.env.VUE_APP_ROLLBAR_API_PROD}`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      // Track your events to a specific version of code for better visibility into version health
      code_version: "1.0.0",
      // Add custom data to your events by adding custom key/value pairs like the one below
      custom_data: "foo",
    },
  });
}

Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  store,
  apolloProvider,
  i18n,
  render: (h) => h(App),
}).$mount("#app")

setupCalendar({
  componentPrefix: "vc",
});
